import React from "react";

export default function CopyIcon() {
  return (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M14.4798 0H6.52582C5.32348 0 4.35236 0.926471 4.35236 2.07353V3.26471H3.15002C1.94768 3.26471 0.976562 4.19118 0.976562 5.33824V12.9265C0.976562 14.0735 1.94768 15 3.15002 15H11.104C12.3063 15 13.2774 14.0735 13.2774 12.9265V11.7353H14.526C15.7283 11.7353 16.6995 10.8088 16.6995 9.66176V2.07353C16.6532 0.926471 15.6821 0 14.4798 0ZM12.1213 12.9265C12.1213 13.4559 11.6589 13.8971 11.104 13.8971H3.15002C2.5951 13.8971 2.13266 13.4559 2.13266 12.9265V5.29412C2.13266 4.76471 2.5951 4.32353 3.15002 4.32353H4.35236V9.66176C4.35236 10.8088 5.32348 11.7353 6.52582 11.7353H12.1213V12.9265ZM15.4971 9.66176C15.4971 10.1912 15.0347 10.6324 14.4798 10.6324H6.52582C5.97089 10.6324 5.50845 10.1912 5.50845 9.66176V2.07353C5.50845 1.54412 5.97089 1.10294 6.52582 1.10294H14.4798C15.0347 1.10294 15.4971 1.54412 15.4971 2.07353V9.66176Z"
        fill="#B7BBBC"
      />
    </svg>
  );
}
