import React from "react";

export default function MultiParty() {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 13.7778V11.1112C4 9.22459 4.90667 7.5018 6.39099 6.43158C5.71425 5.6413 5.33333 4.63176 5.33333 3.55561C5.33333 3.05167 5.42175 2.56882 5.57701 2.1168C5.17262 1.90109 4.71159 1.77783 4.22234 1.77783C2.62945 1.77783 1.33333 3.07395 1.33333 4.66684C1.33333 5.63466 1.81357 6.49108 2.54649 7.0154C1.05007 7.66536 0 9.15632 0 10.8891V12.8892C0 14.1144 0.996978 15.1114 2.22222 15.1114H4.30459C4.11141 14.7065 4 14.2555 4 13.7778Z"
        fill="#192423"
      />
      <path
        d="M17.0086 7.0154C17.7415 6.49084 18.2217 5.63466 18.2217 4.66684C18.2217 3.07395 16.9256 1.77783 15.3327 1.77783C14.8432 1.77783 14.3824 1.90109 13.978 2.1168C14.1333 2.56882 14.2217 3.05167 14.2217 3.55561C14.2217 4.63152 13.8406 5.64106 13.1641 6.43158C14.6484 7.5018 15.5551 9.22482 15.5551 11.1112V13.7778C15.5551 14.2555 15.4436 14.7063 15.2505 15.1112H17.3328C18.5581 15.1112 19.5551 14.1142 19.5551 12.8889V10.8888C19.5551 9.15609 18.505 7.66536 17.0086 7.0154Z"
        fill="#192423"
      />
      <path
        d="M11.6253 6.5875C12.6474 5.96219 13.3331 4.83887 13.3331 3.55556C13.3331 1.59502 11.7381 0 9.77756 0C7.81703 0 6.22201 1.59502 6.22201 3.55556C6.22201 4.83887 6.90775 5.96219 7.92986 6.5875C6.14805 7.31804 4.88867 9.06927 4.88867 11.1111V13.7778C4.88867 15.003 5.88565 16 7.11089 16H12.4442C13.6695 16 14.6664 15.003 14.6664 13.7778V11.1111C14.6664 9.06927 13.4071 7.31804 11.6253 6.5875Z"
        fill="#192423"
      />
    </svg>
  );
}
